export const Endpoints = {
  login: "/login",
  logout: "/logout",
  changePassword: "/change-password",
  addAdmin: "/add-administrators",
  updateAdmin: "/update-administrators",
  deleteAdmin: "/administrators-delete",
  adminList: "/administrators-list",
  resetPassword: (admin_id: string) => { return `/change-password/${admin_id}` },
  dashboard: "/get-dashboard-data",

  // Consumer
  consumerList: "/user-list",
  userDetails: "/single-user-details",
  changeStatus: "/user-active-inactive",
  updateUser: "/user-update",
  deleteUser: "/user-delete",
  addDrone: "/add-drone",

  // Provider
  provideList: "/provider-list",
  providerDetails: "/single-provider-details",
  getReviewFeedback: "/get-review-feedback",
  getDroneService: "/get-drone-service",

  //======== Data Management =========//
  // Crop
  addCropType: "/add-crop-type",
  getCropTypeList: "/get-crop-type-list",
  updateCropType: (crop_id: string) => { return `/update-crop-type/${crop_id}` },
  deleteCropType: (id: string) => { return `/delete-crop-type/${id}` },

  // Pesticide
  addPesticideType: "/add-pesticide-type",
  getPesticideTypeList: "/get-pesticide-type-list",
  updatePesticideType: (pesticide_id: string) => { return `/update-pesticide-type/${pesticide_id}` },
  deletePesticideType: (id: string) => { return `/delete-pesticide-type/${id}` },

  // Price per acer
  addCropPesticide: "/add-crop-pesticide-type",
  getCropPesticideList: "/get-crop-pesticide-price-list",
  deleteCropPesticide: (id: string) => { return `/delete-crop-pesticide-price/${id}` },

  // For dropdown list
  dropdownList: "/get-crop-pesticide-type",

  getBookingHistory: "/get-booking-history",
  getSupportList: "/get-support-list",
  updateSupportStatus: "/support-status-update",

  getTelemetryData: "/get-telemetry-data",

}
