import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  password: boolean;
  confirmpassword: boolean;

  constructor() { }

  ngOnInit(): void {
  }
  showPassword() {
    this.password = !this.password;
  }
  showConfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
}
