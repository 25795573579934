import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Storage_Keys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgotForm: FormGroup;
  password: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private storage: StorageService
  ) {

    this.loginForm = new FormGroup({
      countryCode: new FormControl('91'),
      mobileNumber: new FormControl('', Validators.compose([Validators.required])),
      password: new FormControl('', Validators.compose([Validators.required]))
    });

    this.forgotForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    });
  }

  ngOnInit(): void {
  }

  showPassword() {
    this.password = !this.password;
  }

  login() {
    console.log('login---',this.loginForm.value)
    let payload: any = {
      // "countryCode": '91',
      "mobileNumber": this.loginForm.value.mobileNumber.toLowerCase(),
      "password": this.loginForm.value.password
    }
    console.log('payload',payload);
    this.commonService.presentSpinner();
    this.authService.login(payload).then(
      (res) => {
        if (res && res.Admin) {
          this.commonService.showSuccessToastMsg('', 'Logged in successfully!');
          this.storage.set(Storage_Keys.admin_data, res.Admin);
          let token = {};
          token[Storage_Keys.token] = res.Admin.authToken
          this.storage.set(Storage_Keys.token, token);
          this.router.navigate(['admin/dashboard']);
        }
      }
    ).catch(
      (error) => {

      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    )
  }
}
