import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, Route } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { Storage_Keys } from './services/storage/storage.keys';

class RedirectUrl {
  /**
   *
   * @param path parameter accepts only```''``` (empty path) or ```'**'``` (wild route)
   * @returns  an angular ```Route```
   */
  static getRedirectUrl(path: '' | '**'): Route {
    const savedData = JSON.parse(JSON.parse(localStorage.getItem(Storage_Keys.token)));
    if (savedData && savedData.token) {
      return { path: path, redirectTo: '/admin/dashboard', pathMatch: 'full' };
    } else {
      return { path: path, component: LoginComponent };
    }
  }
}

const routes: Routes = [
  RedirectUrl.getRedirectUrl(''),
  { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule) },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  RedirectUrl.getRedirectUrl('**')
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top',
    useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
