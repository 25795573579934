import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';
import { ListEntries } from './common.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  showSuccessToast(arg0: string) {
    throw new Error('Method not implemented.');
  }

  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private http: HttpWrapperService
  ) { }

  presentSpinner() {
    this.spinner.show();
  }

  dismissSpinner() {
    this.spinner.hide();
  }

  showSuccessToastMsg(title?: string, message?: string) {
    this.toast.success(message, title, {
      timeOut: 2000
    });
  }

  showErrorToastMsg(title?: string, message?: string) {
    this.toast.error(message, title, {
      timeOut: 3000
    });
  }

  matchConfirmPassword(formGroup: FormGroup) {
    const password = formGroup.get('newPassword').value;
    const confirmPassword = formGroup.get('confirmPassword').value;
    let returnAble = password === confirmPassword ? null : { notSame: true };
    return returnAble
  }

  calculateEntries(list: Array<any>, current_page: number, limit: number, totalResult: number): ListEntries {
    if (list.length === totalResult) {
      let pages = {
        1: []
      };
      let arr = [];
      let page = 1;
      for (let i = 0; i < list.length; i++) {
        arr.push(list[i]);
        if (arr.length == limit) {
          pages[page] = arr;
          page += 1;
          arr = [];
        } else {
          if (i == list.length - 1) {
            pages[page] = arr;
            page += 1;
            arr = [];
          }
        }
      }
      list = pages[current_page];
    }
    return {
      from: limit * (current_page - 1) + 0 + 1,
      to: limit * (current_page - 1) + (list.length - 1) + 1,
      total: totalResult,
    };
  }

}
